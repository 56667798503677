import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { DateTime } from "luxon";

export const printMedicalReport = async ({ medicalReportId, fetch, t }) => {
    const endpoints = [
        {
            endpoint: "settings",
            responseData: `_id email phone social_accounts{network account} name address`,
        },
        {
            endpoint: "medicalReport",
            responseData: `_id code patient_name patient_lastname patient_phone patient_email username 
            created_at chief_complaint physical_examinations diagnoses treatment_plan doctor_recomandations`,
            data: {
                _id: medicalReportId,
            },
        },
    ];
    const response = await fetch({
        operation: "query",
        multipleEndpoints: endpoints,
    });
    const { email = "", phone = "", social_accounts, name = "", address = "" } = response?.settings || {};
    const {
        patient_name,
        patient_lastname,
        patient_phone,
        patient_email,
        username,
        created_at,
        code,
        chief_complaint,
        physical_examinations,
        diagnoses,
        treatment_plan,
        doctor_recomandations,
    } = response?.medicalReport || {};

    const doc = new jsPDF("p", "mm", "a4");
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    let row = 20;
    doc.text(name, 15, row);
    row += 10;
    doc.setFontSize(15);
    doc.text(address || "", 15, row);

    autoTable(doc, {
        head: [
            [
                t("Patient"),
                t("Clinic"),
                t("Report_ref") + " " + code,
                t("Date"),
                !!created_at ? DateTime.fromISO(created_at).toFormat("yyyy LLL dd HH:mm") : "",
            ],
        ],
        body: [
            [(patient_name || "") + " " + (patient_lastname || ""), name],
            [patient_email || "", email || ""],
            [patient_phone || "", phone || ""],
        ],
        theme: "plain",
        margin: { top: 40 },
    });
    row += 55;
    doc.setFont("", "regular");
    doc.text("Chief Complaint / Ankesa Kryesore :", 10, row);
    row += 7;
    doc.text(chief_complaint || "", 10, row);
    row += 20;
    doc.text("Physical Examinations / Ekzaminimet fizike", 10, row);
    row += 7;
    doc.text(physical_examinations || "", 10, row);
    row += 20;
    doc.text("Diagnoses / Diagnoza :", 10, row);
    row += 7;
    doc.text(diagnoses || "", 10, row);
    row += 20;
    doc.text("Treatment Plan / Terapia :", 10, row);
    row += 7;
    doc.text(treatment_plan || "", 10, row);
    row += 20;
    doc.text("Doctor Recomandations / Rekomandime te Mjekut :", 10, row);
    row += 7;
    doc.text(doctor_recomandations || "", 10, row);

    doc.text(`Dr. ${username || ""}`, pageWidth - 60, pageHeight - 70);
    doc.text(`____________`, pageWidth - 60, pageHeight - 60);
    doc.setFillColor(217, 217, 217);
    doc.rect(10, pageHeight - 50, pageWidth - 20, 45, "F");
    doc.addImage("/icons/callIcon.png", "png", 20, pageHeight - 34, 5, 5);
    doc.text(phone || "", 30, pageHeight - 30);
    doc.addImage("/icons/emailIcon.png", "png", pageWidth / 2, pageHeight - 34, 5, 5);
    doc.text(email || "", pageWidth / 2 + 10, pageHeight - 30);
    social_accounts?.map((account, i) => {
        const networkImage =
            account.network === "facebook"
                ? "/icons/facebookIcon.png"
                : account.network === "instagram"
                ? "/icons/instagramIcon.png"
                : null;
        if (i % 2 !== 0) {
            if (networkImage) doc.addImage(networkImage, "png", pageWidth / 2, pageHeight - 20, 5, 5);
            doc.text(`${account?.account || ""}`, pageWidth / 2 + 10, pageHeight - 16);
        } else {
            if (networkImage) doc.addImage(networkImage, "png", 20, pageHeight - 20, 5, 5);
            doc.text(`${account?.account || ""}`, 30, pageHeight - 16);
        }
    });

    // Create a promise to handle image loading
    const loadImage = () => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            // Use the full URL of your application
            img.src = window.location.origin + "/images/ClinicSystemLogo.png";

            img.onload = () => {
                const canvas = document.createElement("canvas");
                canvas.width = img.width;
                canvas.height = img.height;

                const ctx = canvas.getContext("2d");
                // Fill with white background first
                ctx.fillStyle = "#FFFFFF";
                ctx.fillRect(0, 0, canvas.width, canvas.height);
                ctx.drawImage(img, 0, 0);

                const imageData = canvas.toDataURL("image/jpeg");
                resolve(imageData);
            };

            img.onerror = reject;
        });
    };

    loadImage()
        .then((imageData) => {
            doc.addImage(imageData, "PNG", (pageWidth - 70) / 2, 10, 60, 30);
            // doc.save("estimate");
            const base64 = doc.output("datauristring");
            const newWindow = window.open();
            newWindow.document.write(`<iframe width='100%' height='100%' src='${base64}'></iframe>`);
        })
        .catch((error) => {
            console.error("Error loading image:", error);
        });
};
