import {
    Alert,
    AlertTitle,
    Avatar,
    Box,
    Breadcrumbs,
    Chip,
    Divider,
    Grid,
    IconButton,
    ListItem,
    ListItemIcon,
    ListItemText,
    Paper,
    Stack,
    Tab,
    Tabs,
    Toolbar,
    Tooltip,
    Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { DeleteButtonWithConfirm, Link } from "../../components/common";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import {
    DocumentsPanel,
    EstimatesPanel,
    PatientAppointmentsPanel,
    PatientDataPanel,
    PatientInvoicePanel,
    PatientOrthoTreatmentPanel,
    PatientPaymentsPanel,
    PatientPrescription,
    PatientsTreatmentsPanel,
    MedicalReportsPanel,
} from "./components";
import { useApi } from "../../components/hooks";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { PatientDataContext } from "./components/PatientDataContext";
import { toCurrency } from "../../utils/toCurrency";
import { DateTime } from "luxon";
import { useSnackbar } from "notistack";

const tabs = [
    "patient",
    "treatment",
    "treatment_orto",
    "invoice",
    "payment",
    "prescription",
    "appointments",
    "estimates",
    "documents",
    "medical_reports",
];

const PatientView2 = () => {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState(1);
    const [patientData, setPatientData] = useState({});
    const [extraData, setExtraData] = useState({});

    const renderPanel = () => {
        switch (activeTab) {
            case 0:
                return <PatientDataPanel />;
            case 1:
                return <PatientsTreatmentsPanel />;
            case 2:
                return <PatientOrthoTreatmentPanel />;
            case 3:
                return <PatientInvoicePanel />;
            case 4:
                return <PatientPaymentsPanel />;
            case 5:
                return <PatientPrescription />;
            case 6:
                return <PatientAppointmentsPanel />;
            case 7:
                return <EstimatesPanel />;
            case 8:
                return <DocumentsPanel />;
            case 9:
                return <MedicalReportsPanel />;
        }
    };

    const params = useParams();
    const { loading, fetch } = useApi();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        loadData();
    }, [params.id]);

    const loadData = async () => {
        const response = await fetch({
            operation: "query",
            multipleEndpoints: [
                {
                    endpoint: "patient",
                    data: { _id: "patients_" + params.id },
                    responseData: `_id code firstname lastname birthday gender address city 
                    phone email number_id profession blood_group allergy note anamneses{question answer}
                    treatment_ortho{doctor diagnose treatment tableData{field field2 value1 value2 value3}}
                    treatments{_id teeth date user_id user_id diagnose_id diagnose_name price status
                        service_id service_name color crown root implant bridge price note username}
                    payments{_id paymentmethod_name amount created_at treatment_id}
                    invoices{_id created_at username total table_data{treatment} note}`,
                },
                {
                    endpoint: "users",
                    data: { activeOnly: true },
                    responseData: "_id username title name",
                },
                {
                    endpoint: "diagnoses",
                    data: { activeOnly: true },
                    responseData: "_id name",
                },
                {
                    endpoint: "services",
                    data: { activeOnly: true },
                    responseData: "_id name color price is_tooth_priced service_category_name",
                },
                {
                    endpoint: "paymentmethods",
                    responseData: "_id name",
                },
                {
                    endpoint: "anamneses",
                    responseData: `_id name`,
                },
                {
                    endpoint: "drugs",
                    responseData: `_id name amount_variants{amount unit}`,
                },
                {
                    endpoint: "settings",
                    responseData: `email phone social_accounts{account}`,
                },
            ],
        });
        if (response?.patient) setPatientData(response.patient);
        if (response)
            setExtraData({
                ...extraData,
                users: response.users
                    ?.filter((user) => user.username !== "sysadmin" && ["Dr", "dr"].includes(user.title))
                    ?.map((user) => ({ value: user._id, label: user.name })),
                anamneses: response.anamneses,
                diagnoses: response.diagnoses?.map((diagnose) => ({ value: diagnose._id, label: diagnose.name })),
                paymentmethods: response.paymentmethods?.map((method) => ({ value: method._id, label: method.name })),
                drugs: response.drugs,
                setting: response.settings,
                services: response.services?.map((service) => ({
                    value: service._id,
                    label: service.name,
                    price: service.price,
                    is_tooth_priced: service.is_tooth_priced,
                    service_category_name: service.service_category_name,
                    color: service.color,
                })),
            });
    };

    const onDeleteHandler = async () => {
        const data = { _id: patientData._id };
        const response = await fetch({
            operation: "mutation",
            endpoint: "deletePatient",
            data,
            responseData: "_id",
        });
        if (response?.deletePatient?._id) {
            enqueueSnackbar(t("deleted_with_success"), { variant: "info" });
            navigate(-1);
        }
    };

    const totalPaidAmount = useMemo(() => {
        if (!patientData.payments) return 0.0;
        return patientData.payments?.reduce((acc, payment) => acc + payment.amount, 0.0);
    }, [patientData.payments]);

    const totalAmount = useMemo(() => {
        if (!patientData.treatments) return 0.0;
        return patientData.treatments
            ?.filter((treatment) => treatment.status !== 0)
            ?.reduce((acc, treatment) => acc + treatment.price, 0.0);
    }, [patientData.treatments]);

    const totalUnpaidAmount = useMemo(() => {
        if (totalPaidAmount >= totalAmount) return 0.0;
        return totalAmount - totalPaidAmount;
    }, [totalPaidAmount, totalAmount]);

    const generateCode = (lastname, number) => {
        // Get the first letter of the lastname and convert to uppercase
        const firstLetter = lastname.charAt(0).toUpperCase();

        // Convert number to string and pad with leading zeros to make it 5 digits
        const paddedNumber = number.toString().padStart(5, "0");

        // Combine the letter and padded number
        return `#${firstLetter}${paddedNumber}`;
    };

    let age = "";
    if (patientData?.birthday) {
        const { years } = DateTime.now().diff(DateTime.fromISO(patientData.birthday), ["years"]);
        age = years.toFixed(0) + " " + t("years_old");
    }
    return (
        <div>
            <Toolbar variant="dense">
                <Breadcrumbs sx={{ marginLeft: "auto" }}>
                    <Link to="/">{t("home")}</Link>
                    <Link to="/patients">{t("patients")}</Link>
                    <Link to={`/patients/${patientData?._id?.split("patients_")[1]}`}>
                        {patientData.firstname} {patientData.lastname}
                    </Link>
                </Breadcrumbs>
            </Toolbar>
            <Paper sx={{ paddingY: 1, borderRadius: "15px", marginBottom: 4 }}>
                <Stack
                    direction="row"
                    justifyContent="space-around"
                    alignItems="center"
                    divider={<Divider orientation="vertical" flexItem variant="middle" />}
                    flexWrap="wrap"
                >
                    <ListItem sx={{ width: "fit-content", gap: 2 }}>
                        <ListItemText
                            primary={`${patientData.firstname || ""} ${patientData.lastname || ""}`}
                            primaryTypographyProps={{ variant: "h6", fontWeight: 600 }}
                            secondary={
                                <Stack>
                                    <Typography>
                                        {patientData.code && generateCode(patientData.lastname, patientData.code)}
                                    </Typography>
                                    <Typography variant="caption">{`${
                                        patientData?.gender ? t(patientData?.gender) : ""
                                    } ${age}`}</Typography>
                                </Stack>
                            }
                        />
                        <ListItemIcon>
                            <IconButton onClick={() => navigate("/patients/" + params.id)}>
                                <BorderColorIcon sx={{ color: "black", fontSize: 24 }} />
                            </IconButton>
                        </ListItemIcon>
                        <DeleteButtonWithConfirm onClick={onDeleteHandler} color="errorLight" />
                    </ListItem>
                    <ListItem sx={{ width: "fit-content" }}>
                        <ListItemText
                            primary={t("total_amount")}
                            primaryTypographyProps={{
                                variant: "subtitle1",
                                color: "grey",
                                textTransform: "capitalize",
                            }}
                            secondary={toCurrency(totalAmount?.toFixed(2))}
                            secondaryTypographyProps={{
                                variant: "h6",
                                fontWeight: 600,
                                color: "black",
                            }}
                        />
                    </ListItem>
                    <ListItem sx={{ width: "fit-content" }}>
                        <ListItemText
                            primary={t("amount_paid")}
                            primaryTypographyProps={{
                                variant: "subtitle1",
                                color: "grey",
                                textTransform: "capitalize",
                            }}
                            secondary={toCurrency(totalPaidAmount?.toFixed(2))}
                            secondaryTypographyProps={{
                                variant: "h6",
                                fontWeight: 600,
                                color: "black",
                                color: "#2ecc71",
                            }}
                        />
                    </ListItem>
                    <ListItem sx={{ width: "fit-content" }}>
                        <ListItemText
                            primary={t("amount_unpaid")}
                            primaryTypographyProps={{
                                variant: "subtitle1",
                                color: "grey",
                                textTransform: "capitalize",
                            }}
                            secondary={toCurrency(totalUnpaidAmount?.toFixed(2))}
                            secondaryTypographyProps={{
                                variant: "h6",
                                fontWeight: 600,
                                color: "black",
                                color: "#e74c3c",
                            }}
                        />
                    </ListItem>
                </Stack>
            </Paper>
            {!!patientData?.allergy && (
                <Alert
                    sx={{
                        paddingY: 0,
                        marginBottom: 4,
                        color: "error.main",
                        fontWeight: 600,
                        backgroundColor: "transparent",
                        borderTop: "none",
                        borderBottom: "none",
                        borderRight: "none",
                        borderLeftWidth: 5,
                    }}
                    severity="error"
                    variant="outlined"
                >
                    <AlertTitle sx={{ marginY: 0, paddingY: 0 }}>{t("allergy")}</AlertTitle>
                    {patientData.allergy}
                </Alert>
            )}
            <Tabs
                value={activeTab}
                onChange={(e, newTab) => setActiveTab(newTab)}
                indicatorColor="secondary"
                textColor="secondary"
                variant="scrollable"
                scrollButtons="auto"
            >
                {tabs.map((tab) => (
                    <Tab sx={{ alignItems: "flex-start" }} label={t(tab)} />
                ))}
            </Tabs>
            <Divider />
            <div style={{ marginTop: 20 }}></div>
            <PatientDataContext.Provider value={{ patientData, setPatientData, extraData }}>
                {renderPanel()}
            </PatientDataContext.Provider>
        </div>
    );
};

export default PatientView2;
