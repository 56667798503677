import React from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    IconButton,
    LinearProgress,
    Toolbar,
    Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Transition } from ".";
import { Form } from "../form";
import { useTranslation } from "react-i18next";
import { ProtectedAccessAction } from "../common";

const Modal = ({
    open,
    loading,
    maxWidth = "sm",
    fullWidth = true,
    fullScreen = false,
    titlelabel,
    children,
    yesText,
    noText,
    yesDisabled = false,
    noDisabled = false,
    onSave,
    onClose,
    FormProps,
    permissions,
    allowMe,
    userId,
}) => {
    const { t } = useTranslation();
    const Content = (
        <>
            <Toolbar>
                <IconButton color="primary" onClick={onClose}>
                    <CloseIcon />
                </IconButton>
                <Typography style={{ marginLeft: "20px" }} variant="h6">
                    {titlelabel}
                </Typography>
            </Toolbar>
            {loading && <LinearProgress color="primary" />}
            <DialogContent>{children}</DialogContent>
            <DialogActions>
                {noText === "none" ? null : (
                    <Button disabled={noDisabled} color="primary" onClick={onClose}>
                        {noText || `${t("cancel")}`}
                    </Button>
                )}
                {yesText === "none" ? null : (
                    <ProtectedAccessAction permissions={permissions} allowMe={allowMe} userId={userId}>
                        <Button
                            variant="contained"
                            disabled={yesDisabled}
                            color="primary"
                            type="submit"
                            onClick={onSave}
                        >
                            {yesText || `${t("save")}`}
                        </Button>
                    </ProtectedAccessAction>
                )}
            </DialogActions>
        </>
    );

    return (
        <Dialog
            fullWidth={fullWidth}
            fullScreen={fullScreen}
            open={open}
            maxWidth={maxWidth}
            onClose={(event, reason) => {
                if (reason !== "backdropClick") {
                    onClose && onClose();
                }
            }}
            TransitionComponent={Transition}
            sx={{ backdropFilter: "blur(2px)" }}
        >
            {FormProps ? <Form {...FormProps}>{Content}</Form> : Content}
        </Dialog>
    );
};

export default Modal;
