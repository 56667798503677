import { Button, Card, CardContent, CardHeader, IconButton, Stack, Typography } from "@mui/material";
import { DateTime } from "luxon";
import { useSnackbar } from "notistack";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AutoCompleteControl, InputControl } from "../../../components/form";
import { useApi } from "../../../components/hooks";
import { Modal } from "../../../components/modal";
import { PatientDataContext } from "./PatientDataContext";
import PrintIcon from "@mui/icons-material/Print";
import { printMedicalReport } from "../../../components/pdf/medicalReportPdf";

const MedicalReportsPanel = () => {
    const [state, setState] = useState({
        openMedicalReportModal: false,
        medicalReportData: null,
    });
    const { t } = useTranslation();
    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();

    const { patientData, extraData, setPatientData } = useContext(PatientDataContext);

    useEffect(() => {
        if (!patientData?.medicalReports) {
            loadPatientMedicalReports();
        }
    }, []);

    const loadPatientMedicalReports = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "patientMedicalReports",
            data: {
                patient_id: patientData._id,
            },
            responseData:
                "_id code user_id username created_at chief_complaint physical_examinations diagnoses treatment_plan doctor_recomandations",
        });
        setPatientData({ ...patientData, medicalReports: response?.patientMedicalReports || [] });
    };

    const saveMedicalReport = async (data) => {
        const medicalReportData = {
            patient_id: patientData._id,
            ...data,
        };
        const response = await fetch({
            operation: "mutation",
            endpoint: "createMedicalReport",
            data: medicalReportData,
            responseData:
                "_id code user_id username created_at chief_complaint physical_examinations diagnoses treatment_plan doctor_recomandations",
        });
        if (response?.createMedicalReport?._id) {
            enqueueSnackbar(t("medical_report_saved"), { variant: "default" });
            setPatientData({
                ...patientData,
                medicalReports: [...(patientData?.medicalReports || []), response?.createMedicalReport],
            });
        }
    };

    const onPrint = (medicalReportId) => {
        printMedicalReport({ medicalReportId: medicalReportId, fetch, t });
    };

    return (
        <div>
            <Button variant="contained" onClick={() => setState({ ...state, openMedicalReportModal: true })}>
                {t("create_new_medical_report")}
            </Button>
            {patientData?.medicalReports && patientData?.medicalReports?.length > 0 && (
                <Stack direction="row" marginTop={2} gap={2}>
                    {patientData?.medicalReports?.map((report) => {
                        return (
                            <Card
                                sx={{ maxWidth: 300, cursor: "pointer" }}
                                onClick={() => {
                                    setState({ ...state, openMedicalReportModal: true, medicalReportData: report });
                                }}
                            >
                                <CardHeader
                                    title={report.code}
                                    action={
                                        <IconButton
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                onPrint(report._id);
                                            }}
                                        >
                                            <PrintIcon />
                                        </IconButton>
                                    }
                                />
                                <CardContent>
                                    <Typography>
                                        {t("doctor")}: {report.username}
                                    </Typography>
                                    <Typography>
                                        {t("created_at")}:{" "}
                                        {report?.created_at &&
                                            DateTime.fromISO(report?.created_at).toFormat("yyyy LLL dd HH:mm")}
                                    </Typography>
                                </CardContent>
                            </Card>
                        );
                    })}
                </Stack>
            )}
            <MedicalReport
                open={state.openMedicalReportModal}
                onClose={() => setState({ ...state, openMedicalReportModal: false, medicalReportData: null })}
                users={extraData?.users || []}
                onSave={saveMedicalReport}
                medicalReportData={state.medicalReportData}
            />
        </div>
    );
};

export default MedicalReportsPanel;

const MedicalReport = ({ open, users = [], onClose, onSave, medicalReportData }) => {
    const { t } = useTranslation();

    const [values, setValues] = useState({});
    console.log(values);

    useEffect(() => {
        if (!open) return;
        setValues(!!medicalReportData ? medicalReportData : {});
    }, [open]);

    return (
        <Modal
            open={open}
            titlelabel={t("medical_report")}
            maxWidth="m"
            onClose={onClose}
            onSave={() => onSave(values)}
            yesDisabled={!!medicalReportData}
        >
            <Stack gap={1}>
                <AutoCompleteControl
                    value={values?.user_id}
                    onChange={(e) => setValues({ ...values, user_id: e.target.value })}
                    options={users || []}
                    label={t("doctor")}
                />
                <Typography textTransform="capitalize">chief complaint / ankesa kryesore :</Typography>
                <InputControl
                    value={values.chief_complaint}
                    onChange={(e) => setValues({ ...values, chief_complaint: e.target.value })}
                    multiline
                    minRows={2}
                />
                <Typography textTransform="capitalize">physical examinations / ekzaminimet fizike :</Typography>
                <InputControl
                    value={values.physical_examinations}
                    onChange={(e) => setValues({ ...values, physical_examinations: e.target.value })}
                    multiline
                    minRows={2}
                />
                <Typography textTransform="capitalize">diagnoses / diagnoza :</Typography>
                <InputControl
                    value={values.diagnoses}
                    onChange={(e) => setValues({ ...values, diagnoses: e.target.value })}
                    multiline
                    minRows={2}
                />
                <Typography textTransform="capitalize">treatment plan / terapia :</Typography>
                <InputControl
                    value={values.treatment_plan}
                    onChange={(e) => setValues({ ...values, treatment_plan: e.target.value })}
                    multiline
                    minRows={2}
                />
                <Typography textTransform="capitalize">doctor recomandations / rekomandime te mjekut :</Typography>
                <InputControl
                    value={values.doctor_recomandations}
                    onChange={(e) => setValues({ ...values, doctor_recomandations: e.target.value })}
                    multiline
                    minRows={2}
                />
            </Stack>
        </Modal>
    );
};
