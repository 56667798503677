import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
    AppointmentsCard,
    QuickActionsCard,
    TotalAppointmentsCard,
    TotalTreatmentsCard,
    TreatmentsCard,
} from "./components";
import { useApi } from "../../components/hooks";
import { DateTime } from "luxon";
import { Outlet } from "react-router-dom";

const Home = () => {
    const [data, setData] = useState({});
    const { loading, fetch } = useApi();

    const todayDate = DateTime.now().toFormat("yyyy-LL-dd");

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        const response = await fetch({
            operation: "query",
            multipleEndpoints: [
                {
                    endpoint: "users",
                    responseData: "_id username name title",
                },
                {
                    endpoint: "appointments",
                    data: {
                        startDate: todayDate,
                        endDate: todayDate,
                    },
                    responseData: "_id start_time end_time user_id username user_color name",
                },
                {
                    endpoint: "treatments",
                    data: {
                        startDate: todayDate,
                        endDate: todayDate,
                    },
                    responseData: "patient_id user_id username user_color service_name",
                },
            ],
        });
        if (response) {
            setData({
                doctorOptions: response?.users
                    ?.filter((user) => user.username !== "sysadmin" && ["Dr", "dr"].includes(user.title))
                    ?.map((user) => ({ value: user._id, label: user.name })),
                appointments: response?.appointments,
                treatments: response?.treatments,
            });
        }
    };

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    <QuickActionsCard />
                </Grid>
                <Grid item xs={12} md={4}>
                    <TotalAppointmentsCard
                        appointments={data?.appointments || []}
                        doctorOptions={data?.doctorOptions || []}
                        loading={loading}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <TotalTreatmentsCard
                        treatments={data?.treatments || []}
                        doctorOptions={data?.doctorOptions || []}
                        loading={loading}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <AppointmentsCard
                        appointments={data?.appointments || []}
                        doctorOptions={data?.doctorOptions || []}
                        loading={loading}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TreatmentsCard
                        treatments={data?.treatments || []}
                        doctorOptions={data?.doctorOptions || []}
                        loading={loading}
                    />
                </Grid>
            </Grid>
            <Outlet />
        </>
    );
};

export default Home;
