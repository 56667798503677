import { Card, CardContent, CardHeader, Grid, Stack } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useContext, useEffect, useState } from "react";
import { PatientDataContext } from "../../views/patients/components/PatientDataContext";
import { AutoCompleteControl, InputControl, RadioGroupControl, SelectControl } from "../form";
import { Modal } from "../modal";
import { useTranslation } from "react-i18next";
import ProtectedAccessAction from "./ProtectedAccessAction";
import DeleteButtonWithConfirm from "./DeleteButtonWithConfirm";
import { useApi } from "../hooks";

const PrescriptionModal = ({ open, data = {}, onSave, onClose, removePrescription }) => {
    const dtdnOptions = [
        {
            value: "tablet",
            label: "tablet",
        },
        {
            value: "syrup",
            label: "syrup",
        },
        {
            value: "tablets",
            label: "tablets",
        },
        {
            value: "pills",
            label: "pills",
        },
        {
            value: "capsules",
            label: "capsules",
        },
        {
            value: "sprays",
            label: "sprays",
        },
        {
            value: "paste",
            label: "paste",
        },
        {
            value: "gels",
            label: "gels",
        },
        {
            value: "sol",
            label: "sol",
        },
    ];
    const [values, setValues] = useState(data);
    const { extraData } = useContext(PatientDataContext);
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    useEffect(() => {
        if (open === true) setValues(data);
    }, [open]);

    const selectDrug = (id, amount, unit, description = "", dtdn = "", dtdnUnit = "") => {
        if (amount) amount = parseFloat(amount);
        if (!values.drugs) {
            setValues({ ...values, drugs: [{ id, amount, unit, description, dtdn, dtdnUnit }] });
            return;
        }
        if (values.drugs.some((drug) => drug.id === id)) {
            setValues({
                ...values,
                drugs: values.drugs.map((drug) => {
                    if (drug.id === id) return { id, amount, unit, description, dtdn, dtdnUnit };
                    return drug;
                }),
            });
            return;
        }
        setValues({ ...values, drugs: [...values.drugs, { id, amount, unit, description, dtdn, dtdnUnit }] });
    };

    const { loading, fetch } = useApi();

    const onDeleteHandler = async () => {
        const data = { _id: values._id };

        const response = await fetch({
            operation: "mutation",
            endpoint: "deletePrescription",
            data,
            responseData: "_id",
        });
        if (response?.deletePrescription?._id) {
            enqueueSnackbar(t("deleted_with_success"), { variant: "info" });
            if (removePrescription) {
                removePrescription(response?.deletePrescription?._id);
            }
        }
    };

    return (
        <Modal
            open={open}
            titlelabel={t("prescription")}
            maxWidth="m"
            onClose={onClose}
            onSave={() => {
                if (!values.drugs || values.drugs.length === 0) {
                    enqueueSnackbar(t("empty_drugs_list"), { variant: "warning" });
                    return;
                }
                onSave && onSave(values);
                onClose();
            }}
            permissions={{ prescriptions: values?._id ? "update" : "create" }}
        >
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <AutoCompleteControl
                        value={values?.user_id}
                        onChange={(e) => setValues({ ...values, user_id: e.target.value })}
                        options={extraData?.users || []}
                        label={t("doctor")}
                        sx={{ marginBottom: 2 }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <InputControl
                        label={t("diagnose")}
                        value={values?.diagnose}
                        onChange={(e) => setValues({ ...values, diagnose: e.target.value })}
                    />
                </Grid>
            </Grid>
            <Stack direction="row" flexWrap="wrap" gap={4}>
                {extraData?.drugs?.map((drug) => {
                    const { id, amount, unit, description, dtdn, dtdnUnit } =
                        values.drugs?.find((d) => d.id === drug._id) || {};
                    return (
                        <Card sx={{ borderRadius: 5, width: 300 }}>
                            <CardHeader title={drug.name} />
                            <CardContent>
                                <RadioGroupControl
                                    options={drug?.amount_variants.map((variant) => {
                                        return {
                                            value: variant.amount + " " + variant.unit,
                                            label: variant.amount + variant.unit,
                                        };
                                    })}
                                    onChange={(e) =>
                                        selectDrug(
                                            drug._id,
                                            e.target.value?.split(" ")?.[0],
                                            e.target.value?.split(" ")?.[1],
                                            description,
                                            dtdn,
                                            dtdnUnit
                                        )
                                    }
                                    value={amount + " " + unit}
                                />

                                <InputControl
                                    value={dtdn}
                                    onChange={(e) =>
                                        selectDrug(drug._id, amount, unit, description, e.target.value, dtdnUnit)
                                    }
                                    label={t("dtdn")}
                                />
                                <SelectControl
                                    value={dtdnUnit}
                                    onChange={(e) =>
                                        selectDrug(drug._id, amount, unit, description, dtdn, e.target.value)
                                    }
                                    label={t("dtdn_unit")}
                                    options={dtdnOptions}
                                />
                                <InputControl
                                    value={description}
                                    onChange={(e) => selectDrug(drug._id, amount, unit, e.target.value, dtdn, dtdnUnit)}
                                    label={t("description")}
                                    multiline
                                    minRows={2}
                                />
                            </CardContent>
                        </Card>
                    );
                })}
            </Stack>
            <ProtectedAccessAction permissions={{ payments: "delete" }}>
                <Grid item xs={12} sx={{ textTransform: "capitalize" }}>
                    <DeleteButtonWithConfirm onClick={onDeleteHandler} color="errorLight" /> {t("delete")}{" "}
                    {t("prescription")}
                </Grid>
            </ProtectedAccessAction>
        </Modal>
    );
};

export default PrescriptionModal;
