import { Button, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { AutoCompleteControl, InputControl } from "../../components/form";
import { DateControl, TimeControl } from "../../components/dates";
import { Modal } from "../../components/modal";
import { useApi } from "../../components/hooks";
import { useSnackbar } from "notistack";
import { ClientSearch } from "../../components/common";
import { useTranslation } from "react-i18next";

const rules = {
    date: "required",
    start_time: "required",
    end_time: "required",
    user_id: "required",
    name: "required",
    // patient_id: "required",
};

const AppointmentForm = () => {
    const [values, setValues] = useState({});
    const [doctors, setDoctors] = useState([]);
    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const params = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const { patientId, date, start_time, end_time, onSaveEffect, onDeleted, user_id } = useOutletContext() || {};
    useEffect(() => {
        if (patientId || date || start_time || end_time || user_id)
            setValues({ ...values, patient_id: patientId, date, start_time, end_time, user_id });
    }, []);

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        const endpoints = [
            {
                endpoint: "users",
                data: { activeOnly: true },
                responseData: "_id username name title",
            },
        ];
        if (params.appointmentId !== "create")
            endpoints.push({
                endpoint: "appointment",
                data: {
                    _id: "appointments_" + params?.appointmentId,
                },
                responseData: "_id date start_time end_time user_id name note patient_id",
            });
        const response = await fetch({
            operation: "query",
            multipleEndpoints: endpoints,
        });
        if (response?.appointment) setValues(response.appointment);
        if (response?.users)
            setDoctors(
                response.users
                    ?.filter((user) => user.username !== "sysadmin" && ["Dr", "dr"].includes(user.title))
                    .map((user) => ({ value: user._id, label: user.name }))
            );
    };

    const createNewPatient = async (patientName) => {
        const firstname = patientName.split(" ")?.[0];
        const lastname = patientName.split(" ")?.[1];
        const newPatientData = { firstname: firstname, lastname: lastname, phone: "000" };
        const response = await fetch({
            operation: "mutation",
            endpoint: "createPatient",
            data: newPatientData,
            responseData: "_id",
        });
        const newPatientId = response?.createPatient?._id;
        if (!newPatientId) {
            enqueueSnackbar(t("something_went_wrong"), { variant: "error" });
            return;
        }
        return newPatientId;
    };

    const saveHandler = async () => {
        const { date, start_time, end_time, user_id, name, note, patient_id, _id, new_patient } = values;
        const data = {
            date,
            start_time,
            end_time,
            user_id,
            name,
            note,
            patient_id,
        };
        if (_id) data._id = _id;
        if (!patient_id && !!new_patient) {
            const newPatientId = await createNewPatient(new_patient);
            data.patient_id = newPatientId;
        }
        if (!data.patient_id) {
            enqueueSnackbar(t("patient_missing"), { variant: "warning" });
            return;
        }
        const response = await fetch({
            operation: "mutation",
            endpoint: _id ? "updateAppointment" : "createAppointment",
            data,
            responseData:
                "_id date start_time end_time user_id username user_color name note patient_name patient_lastname",
        });
        if (response?.createAppointment?._id || response?.updateAppointment?._id) {
            enqueueSnackbar(t("appointment_saved"), { variant: "default" });

            /** Trigger onSaveEffect (provided by another screen) if another screen is waiting for response */
            if (onSaveEffect) onSaveEffect(_id ? response.updateAppointment : response.createAppointment);

            navigate(-1);
        }
    };

    const onDeleteHandler = async () => {
        const response = await fetch({
            operation: "mutation",
            endpoint: "deleteAppointment",
            data: {
                _id: values?._id,
            },
            responseData: "_id",
        });
        if (response?.deleteAppointment?._id) {
            enqueueSnackbar(t("deleted_with_success"), { variant: "info" });

            onDeleted && onDeleted(response.deleteAppointment._id);
            navigate(-1);
        }
    };

    return (
        <Modal
            loading={loading}
            titlelabel={t("appointment_form")}
            maxWidth="xs"
            open={true}
            onClose={() => navigate(-1)}
            FormProps={{
                values,
                onValuesChange: setValues,
                onSubmit: saveHandler,
                rules,
            }}
            permissions={{ appointments: values?._id ? "update" : "create" }}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <DateControl name="date" label={t("date")} />
                </Grid>
                <Grid item xs={6}>
                    <TimeControl name="start_time" label={t("start_time")} />
                </Grid>
                <Grid item xs={6}>
                    <TimeControl name="end_time" label={t("end_time")} />
                </Grid>
                <Grid item xs={12}>
                    <ClientSearch
                        patientId={values?.patient_id}
                        onPatientSelect={({ value }) => setValues({ ...values, patient_id: value })}
                    />
                </Grid>
                <Grid item xs={12}>
                    <InputControl name="new_patient" label={t("new_patient")} />
                </Grid>
                <Grid item xs={12}>
                    <AutoCompleteControl name="user_id" label={t("doctor")} options={doctors} />
                </Grid>
                <Grid item xs={12}>
                    <InputControl name="name" label={t("name")} />
                </Grid>
                <Grid item xs={12}>
                    <InputControl name="note" label={t("note")} multiline minRows={2} />
                </Grid>
                {values?._id && (
                    <Grid item xs={12}>
                        <Button onClick={() => onDeleteHandler()} variant="contained">
                            {t("delete")}
                        </Button>
                    </Grid>
                )}
            </Grid>
        </Modal>
    );
};

export default AppointmentForm;
