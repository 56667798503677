import { Grid, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { AutoCompleteControl, InputControl, SelectControl } from "../../components/form";
import { useApi } from "../../components/hooks";
import { Modal } from "../../components/modal";
import { ClientSearch, DeleteButtonWithConfirm, ProtectedAccessAction } from "../../components/common";
import { useTranslation } from "react-i18next";

const PaymentForm = () => {
    const [values, setValues] = useState({});
    const [doctors, setDoctors] = useState([]);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [treatments, setTreatments] = useState([]);
    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const params = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const { patientId, onSaveEffect, addPayment, onDeleteEffect } = useOutletContext() || {};

    useEffect(() => {
        if (patientId) setValues({ ...values, patient_id: patientId });
    }, []);

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        loadPatientTreatments();
    }, [values.patient_id]);

    const loadData = async () => {
        const endpoints = [
            {
                endpoint: "users",
                data: { activeOnly: true },
                responseData: "_id username title name",
            },
            {
                endpoint: "paymentmethods",
                responseData: "_id name",
            },
        ];
        if (params.paymentId !== "create") {
            endpoints.push({
                endpoint: "payment",
                data: {
                    _id: "payments_" + params?.paymentId,
                },
                responseData: "_id patient_id user_id payment_method_id note amount treatment_id",
            });
        }
        const response = await fetch({
            operation: "query",
            multipleEndpoints: endpoints,
        });
        if (response?.payment) setValues(response.payment);
        if (response?.users)
            setDoctors(
                response.users
                    ?.filter((user) => user.username !== "sysadmin" && ["Dr", "dr"].includes(user.title))
                    ?.map((user) => ({ value: user._id, label: user.name }))
            );
        if (response?.paymentmethods)
            setPaymentMethods(response.paymentmethods.map((method) => ({ value: method._id, label: method.name })));
    };

    const loadPatientTreatments = async () => {
        if (!values.patient_id) {
            setTreatments([]);
            return;
        }
        const response = await fetch({
            operation: "query",
            endpoint: "patient",
            data: { _id: values.patient_id },
            responseData: "treatments{_id service_name price}",
        });
        if (response?.patient?.treatments) {
            setTreatments(
                response?.patient?.treatments.map(({ _id, service_name, price }) => ({
                    value: _id,
                    label: service_name,
                    price,
                }))
            );
        }
    };

    const saveHandler = async () => {
        const { patient_id, user_id, payment_method_id, note, amount, treatment_id, _id } = values;
        const data = {
            patient_id,
            user_id,
            payment_method_id,
            note,
            amount: parseFloat(amount),
            treatment_id,
        };
        if (_id) data._id = _id;
        const response = await fetch({
            operation: "mutation",
            endpoint: _id ? "updatePayment" : "createPayment",
            data,
            responseData:
                "_id username paymentmethod_name amount created_at treatment_id patient_name patient_lastname",
        });
        if (response?.createPayment?._id || response?.updatePayment?._id) {
            enqueueSnackbar(t("payment_saved"), { variant: "default" });
            /** Trigger onSaveEffect (provided by another screen) if another screen is waiting for response */
            if (onSaveEffect) onSaveEffect(_id ? response.updatePayment : response.createPayment);

            if (addPayment) addPayment(_id ? response.updatePayment : response.createPayment);

            navigate(-1);
        }
    };

    const onDeleteHandler = async () => {
        const data = { _id: "payments_" + params?.paymentId };

        const response = await fetch({
            operation: "mutation",
            endpoint: "deletePayment",
            data,
            responseData: "_id",
        });
        if (response?.deletePayment?._id) {
            enqueueSnackbar(t("deleted_with_success"), { variant: "info" });
            if (onDeleteEffect) onDeleteEffect(response?.deletePayment?._id);
            navigate(-1);
        }
    };

    return (
        <Modal
            titlelabel={t("payment_form")}
            maxWidth="xs"
            open={true}
            onClose={() => navigate(-1)}
            FormProps={{
                values,
                onValuesChange: setValues,
                onSubmit: saveHandler,
            }}
            loading={loading}
            permissions={{ payments: values?._id ? "update" : "create" }}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <ClientSearch
                        patientId={values?.patient_id}
                        onPatientSelect={({ value }) => setValues({ ...values, patient_id: value })}
                    />
                </Grid>
                <Grid item xs={12}>
                    <AutoCompleteControl options={doctors} name="user_id" label={t("doctor")} />
                </Grid>
                <Grid item xs={12}>
                    <AutoCompleteControl
                        options={treatments}
                        name="treatment_id"
                        label={t("treatment")}
                        onChange={(e) => {
                            const { value, price } = treatments.find((t) => t.value === e.target.value) || {};
                            setValues({ ...values, treatment_id: value, amount: price });
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <SelectControl options={paymentMethods} name="payment_method_id" label={t("payment_method")} />
                </Grid>
                <Grid item xs={6}>
                    <InputControl isCurrencyInput name="amount" label={t("amount")} type="number" />
                </Grid>
                <Grid item xs={12}>
                    <InputControl name="note" label={t("note")} multiline minRows={2} />
                </Grid>
                <ProtectedAccessAction permissions={{ payments: "delete" }}>
                    <Grid item xs={12} sx={{ textTransform: "capitalize" }}>
                        <DeleteButtonWithConfirm onClick={onDeleteHandler} color="errorLight" /> {t("delete")}{" "}
                        {t("payment")}
                    </Grid>
                </ProtectedAccessAction>
            </Grid>
        </Modal>
    );
};

export default PaymentForm;
