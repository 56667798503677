import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Grid,
    LinearProgress,
    Paper,
    Stack,
    Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { CurrenciesTotalTable, ProtectedAccessAction, ToothsChart } from "../../components/common";
import TableData from "./components/TableData";
import { useApi } from "../../components/hooks";
import { AutoCompleteControl, Form, InputControl } from "../../components/form";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import { ClientSearch } from "../../components/common";
import { printEstimate } from "../../components/pdf/estimatePdf";
import { useTranslation } from "react-i18next";
import { toCurrency } from "../../utils/toCurrency";

let rules = {
    "table_data.*.treatment": "required",
    "table_data.*.price": "required",
};
const EstimateForm = () => {
    const [values, setValues] = useState({});
    const [treatmentOptions, setTreatmentOptions] = useState([]);
    const [users, setUsers] = useState([]);
    const [currencies, setCurrencies] = useState([]);
    const [currenciesValues, setCurrenciesValues] = useState([]);

    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const params = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        const endpoints = [
            {
                endpoint: "services",
                data: { activeOnly: true },
                responseData: "_id name price",
            },
            {
                endpoint: "users",
                data: { activeOnly: true },
                responseData: "_id name username title",
            },
            {
                endpoint: "currencies",
                responseData: "_id name code rate",
            },
        ];
        if (params.id !== "create") {
            endpoints.push({
                endpoint: "estimate",
                data: { _id: "estimates_" + params.id },
                responseData:
                    "_id code patient_id patient_email table_data{tooth treatment price description} total user_id discount note",
            });
        }
        const response = await fetch({
            operation: "query",
            multipleEndpoints: endpoints,
        });
        if (response?.services) {
            setTreatmentOptions(
                response.services.map((t) => ({
                    value: t._id,
                    label: !!t.name ? t.name.charAt(0).toUpperCase() + t.name.slice(1) : "",
                    price: t.price,
                }))
            );
        }
        if (response?.users) {
            setUsers(
                response.users
                    ?.filter((user) => user.username !== "sysadmin" && ["Dr", "dr"].includes(user.title))
                    ?.map((user) => ({ value: user._id, label: user.name }))
            );
        }
        if (response?.estimate) setValues(response.estimate);
        if (response?.currencies) {
            setCurrencies(response?.currencies?.map((c) => ({ value: c.code, label: c.code, rate: c.rate })));
        }
    };

    const calculateTotal = useMemo(() => {
        let totalPrice = values.table_data?.reduce((total, item) => {
            const price = parseFloat(item.price);
            if (!isNaN(price)) total += price;
            return total;
        }, 0.0);
        const discount = parseFloat(values.discount);
        if (!isNaN(discount)) totalPrice -= discount;
        return totalPrice || 0.0;
    }, [values.table_data, values.discount]);

    const calculateEuroRate = useMemo(() => {
        const total = parseFloat(calculateTotal) * parseFloat(values.euroRate);
        if (isNaN(total)) return "";
        return total?.toFixed(2);
    }, [calculateTotal, values.euroRate]);

    const calculateUSDRate = useMemo(() => {
        const total = parseFloat(calculateTotal) * parseFloat(values.usdRate);
        if (isNaN(total)) return "";
        return total?.toFixed(2);
    }, [calculateTotal, values.usdRate]);

    const saveHandler = async () => {
        const { patient_id, table_data, user_id, discount, note, _id } = values;
        const data = {
            patient_id,
            user_id,
            discount: !!discount ? parseFloat(discount) : null,
            note,
            table_data: table_data
                ?.filter((item) => !!item.treatment && !!item.price)
                ?.map((item) => {
                    item.price = parseFloat(item.price);
                    return item;
                }),
            total: calculateTotal,
        };
        if (_id) data._id = _id;
        const response = await fetch({
            operation: "mutation",
            endpoint: _id ? "updateEstimate" : "createEstimate",
            data,
            responseData: "_id",
        });
        if (response?.updateEstimate?._id || response?.createEstimate?._id) {
            enqueueSnackbar(t("estimate_saved"), { variant: "default" });
            navigate(-1);
        }
    };

    const generatePdf = () => {
        if (!values?._id) return;
        try {
            printEstimate({
                estimateId: values?._id,
                fetch,
                t,
                currenciesValues: currenciesValues,
                returnBase64: false,
            });
        } catch (err) {
            enqueueSnackbar(t("print_failed"), { variant: "warning" });
        }
    };

    const sendEstimateEmail = async () => {
        try {
            const base64Pdf = await printEstimate({
                estimateId: values?._id,
                fetch,
                t,
                currenciesValues: currenciesValues,
                returnBase64: true,
            });
            const response = await fetch({
                operation: "mutation",
                endpoint: "emailEstimate",
                data: { pdf: base64Pdf, patientEmail: values?.patient_email },
            });
            if (response?.emailEstimate === "success") {
                enqueueSnackbar(t("email_sent"), { variant: "success" });
                return;
            }
            throw new Error("failed");
        } catch (err) {
            enqueueSnackbar(t("failed"), { variant: "warning" });
        }
    };

    return (
        <div>
            {loading && <LinearProgress color="primary" />}
            <Box sx={{ overflowX: "scroll" }}>
                <ToothsChart
                    onSelect={(tooth) =>
                        setValues({ ...values, table_data: [...(values.table_data || []), { tooth }] })
                    }
                />
            </Box>
            <Paper sx={{ marginTop: 2, padding: 2 }}>
                <TableData
                    treatmentOptions={treatmentOptions}
                    data={values.table_data || []}
                    onChange={(newData) => setValues({ ...values, table_data: newData })}
                />
                <Form values={values} onValuesChange={setValues} rules={rules} onSubmit={saveHandler}>
                    <Grid container marginTop={2} alignItems="end" spacing={2}>
                        <Grid item xs={12} md={6}>
                            <InputControl disabled name="code" label={t("estimate_code")} />
                            <ClientSearch
                                patientId={values?.patient_id}
                                onPatientSelect={({ value }) => setValues({ ...values, patient_id: value })}
                            />
                            <AutoCompleteControl options={users} name="user_id" label={t("doctor")} />
                            <InputControl name="note" multiline minRows={3} label={t("note")} />
                        </Grid>
                        <Grid item xs={12} md={6} marginLeft="auto">
                            <Card variant="outlined" sx={{ boxShadow: "none" }}>
                                <CardContent>
                                    <InputControl type="number" name="discount" label={t("discount")} />
                                    <Typography marginTop={2} fontWeight="bold">
                                        {t("total")}:
                                    </Typography>
                                    <Typography>{toCurrency(calculateTotal?.toFixed(2))}</Typography>
                                </CardContent>
                                <CardActions>
                                    <ProtectedAccessAction
                                        permissions={{ estimates: values?._id ? "update" : "create" }}
                                    >
                                        <Button type="submit" fullWidth variant="outlined" color="primary">
                                            {t("save")}
                                        </Button>
                                    </ProtectedAccessAction>
                                    {values?._id && (
                                        <Button
                                            fullWidth
                                            onClick={() => generatePdf()}
                                            variant="outlined"
                                            color="primary"
                                        >
                                            {t("print")}
                                        </Button>
                                    )}
                                </CardActions>
                                <CardActions>
                                    <Button
                                        fullWidth
                                        onClick={() => sendEstimateEmail()}
                                        variant="outlined"
                                        color="primary"
                                        disabled={!values?.patient_email}
                                    >
                                        {t("email")}
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    </Grid>
                </Form>
                <div style={{ marginTop: 20 }}>
                    <CurrenciesTotalTable
                        currencies={currencies}
                        onChange={setCurrenciesValues}
                        data={currenciesValues}
                        total={calculateTotal}
                    />
                </div>
            </Paper>
        </div>
    );
};

export default EstimateForm;
